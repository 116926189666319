import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  protected apiUrl = environment.apiUrl + '/upload/temp';

  constructor(private http: HttpClient) {}

  uploadFile(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    const req = new HttpRequest('POST', this.apiUrl, formData, {
      reportProgress: true,
      responseType: 'json',
    });

    return this.http.request(req);
  }

  downloadBlob(blob: Blob, fileName: string): void {
    const a = document.createElement('a');
    const objectUrl = URL.createObjectURL(blob);
    a.href = objectUrl;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(objectUrl);
  }

  downloadUrl(url: string, fileName = 'document'): void {
    if (url) {
      const anchor = document.createElement('a');
      anchor.href = url;

      // Set the download attribute with a default filename or title
      anchor.download = fileName;

      // Optional: set rel attribute to prevent security risks
      anchor.rel = 'noopener';

      // Append the anchor to the body (necessary for Firefox)
      document.body.appendChild(anchor);

      // Trigger the download by simulating a click
      anchor.click();

      // Remove the anchor from the body after download starts
      document.body.removeChild(anchor);
    } else {
      console.error('Download URL is not available.');
    }
  }

}
